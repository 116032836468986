<template>
  <button
    :class="['v-button', element.type == 'round' && 'roundBtn', fontFamilyCls]"
    :style="previewStyle"
    @click.stop="mouseEventHandler('down')"
  >
    {{ propValue }}
  </button>
</template>

<script>
// import { mapState } from "vuex";
import { getCurComponentsInd } from '@/utils/utils';
import { fontFamilyMap } from '@/utils/attrNameData';
export default {
  props: {
    propValue: {
      type: String,
      default: "",
    },
    element: {
      type: Object,
      default: () => {},
    },
    isPreview: Boolean,
    currentComps: {
      type: String,
      require: false
    },
    allBtnPressMap: {
      type: Object,
      require: false
    }
  },
  data() {
    return {
      isPreviewPress: false
    };
  },
  computed: {
    fontFamilyCls() {
      let cls = '';
      if (this.element.style && this.element.style.fontFamily) {
        cls = 'diy-' + fontFamilyMap[this.element.style.fontFamily];
      }
      return cls;
    },
    previewStyle() {
      if (this.isPreview) {
        // 预览页面的按钮样式设置
        const {
          pressBackgroundImage,
          pressBackgroundColor,
          backgroundImage,
          backgroundColor,
        } = this.element.style || {};
        return this.allBtnPressMap[this.element.id] ? {
            backgroundImage: pressBackgroundImage,
            backgroundColor: pressBackgroundColor
        } : {
            backgroundImage: backgroundImage,
            backgroundColor: backgroundColor
        };
      }
      return undefined;
    }
  },
  // computed: mapState(["componentData", "curComponent"]),
  methods: {
    previewEventHandler(type) {
      if (this.element.style.oneClick && this.element.events && this.element.events.jumpEvent && this.element.events.jumpEvent.length !== 0) {
        // 是单击按钮，并且配置了跳转事件；效果预览切换页面
        this.$emit('changePage', this.element.events.jumpEvent[0]);
      }
      // console.log(this.element);
      let id = this.element.id;
      let curentIsPress = !!this.allBtnPressMap[id];
      // 联动事件，按钮由非按压变成按压时候触发
      let pressEvent = this.element.events.pressEvent;
      if (!curentIsPress && pressEvent.length !== 0) {
        let arr = [];
        pressEvent.map((item) => {
          arr.push({
            id: item.id,
            isPress: item.status !== '0'
          });
        });
        this.$emit("changeBtnPress", arr);
      }
      // 再次按压联动事件，按钮由按压变成非按压时候触发
      let pressAgainEvent = this.element.events.pressAgainEvent;
      if (curentIsPress && pressAgainEvent.length !== 0) {
        let arr = [];
        pressAgainEvent.map((item) => {
          arr.push({
            id: item.id,
            isPress: item.status !== '0'
          });
        });
        this.$emit("changeBtnPress", arr);
      }
      // 互斥事件
      let mutuallyEvent = this.element.events.mutuallyEvent;
      if (mutuallyEvent && mutuallyEvent.length !== 0) {
        let arr = [];
        mutuallyEvent.map((id) => {
          arr.push({
            id: id,
            isPress: curentIsPress
          });
        });
        this.$emit("changeBtnPress", arr);
      }
    },
    mouseEventHandler(type) {
      if (!this.isPreview) return;
      this.previewEventHandler(type)
      // console.log(this.element.id);
      this.$emit("changeBtnPress", [
        { id: this.element.id, isPress: !this.allBtnPressMap[this.element.id] }
      ]);
      // this.isPreviewPress = !this.isPreviewPress;
      // const all = this.currentComps ? JSON.parse(this.currentComps) : [];
      // const index = all.findIndex(
      //   (i) => i.id == this.element.id
      // );
      // const ret = getCurComponentsInd(all, this.element);
      // const {
      //   backgroundColor,
      //   backgroundImage,
      //   pressBackgroundImage,
      //   pressBackgroundColor,
      //   originBackImage,
      //   originBackColor,
      // } = this.element.style || {};
      // let obj = {};
      // if (type == "down") {
      //   obj = {
      //     ...this.element,
      //     style: {
      //       ...this.element.style,
      //       originBackColor: backgroundColor,
      //       originBackImage: backgroundImage,
      //       backgroundImage: pressBackgroundImage,
      //       backgroundColor: pressBackgroundColor,
      //     },
      //   };
      // } else {
      //   obj = {
      //     ...this.element,
      //     style: {
      //       ...this.element.style,
      //       backgroundImage: originBackImage,
      //       backgroundColor: originBackColor,
      //     },
      //   };
      // }

      // all.splice(index, 1, obj);
      // if (ret.parentInd !== -1) {
      //   // 生态组件下的子组件
      //   all[ret.parentInd].propValue.splice(ret.index, 1, obj);
      // } else {
      //   all.splice(ret.index, 1, obj);
      // }
      // this.$store.commit("setComponentData", all);
      // this.$emit('changeCurComp', all);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-button {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background: #fff;
  border: 1px solid #dcdfe6;
  color: #606266;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  transition: 0.1s;
  font-weight: 500;
  width: 100%;
  height: 100%;
  font-size: 14px;
  background-size: 100% 100%;
  white-space: pre-line;
  &:active {
    color: #3a8ee6;
    border-color: #3a8ee6;
    outline: 0;
  }

  &:hover {
    background-color: #ecf5ff;
    color: #3a8ee6;
  }
}
.roundBtn {
  border-radius: 50% !important;
}
</style>
